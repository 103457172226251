import React, {useEffect, useState} from 'react';
import ToolboxHeader from "../ToolboxHeader";
import CreateEditCouponModal from "./CreateEditCouponModal";
import baseApi from "../../../apis/baseApi";
import TableHeader from "../../../components/table/TableHeader";
import {Table} from 'react-bootstrap';
import EmptyTr from "../../../components/table/rows/EmptyTr";
import TableActionsCell from "../../../components/table/TableActionsCell";
import {formatDateTime} from "../../../utils/dateFormatters";
import DeleteModal from "../../../components/modals/DeleteModal";

export interface Coupon {
    id: string;
    title: string;
    redeem_code: string | null;
    discount_amount: number;
    is_percent: boolean;
    activate_on: string;
    expire_on: string;
    created_on: string;
    restrictions: CouponRestrictions | null;
}

export interface CouponRestrictions {
    max_redeems_total: number | null;
    max_redeems_per_user: number | null;
    min_purchase_total: number | null;
    allow_all_plans: boolean;
}

const Coupons = () => {
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [coupons, setCoupons] = useState<Coupon[]>([]);
    const [selectedCoupon, setSelectedCoupon] = useState<Coupon>();

    useEffect(() => {
        baseApi.get("/coupons/").then(resp => {
            setCoupons(resp.data.results)
        }).catch()
    }, []);

    return (
        <div className="p-5">
            <CreateEditCouponModal
                coupon={selectedCoupon}
                show={showCreateModal}
                onHide={() => {
                    setShowCreateModal(false)
                    setSelectedCoupon(undefined)
                }}
                onCreate={(coupon) => {
                    const idx = coupons.findIndex(c => c.id === coupon.id)
                    if (idx >= 0) {
                        coupons[idx] = coupon
                        setCoupons(coupons)
                    } else {
                        setCoupons([coupon, ...coupons])
                    }
                }}
            />
            <DeleteModal url={`/coupons/${selectedCoupon?.id}/`} onSuccess={() => {
                setCoupons(coupons.filter(c => c.id !== selectedCoupon?.id))
            }} show={showDeleteModal} onHide={() => {
                setSelectedCoupon(undefined)
                setShowDeleteModal(false)
            }}>
                <div>
                    ¿Seguro que deseas eliminar y cancelar este cupón?
                    <p className="font-weight-bolder p-0 m-0">{selectedCoupon?.title}</p>
                    <p className="font-weight-bolder p-0 m-0">{selectedCoupon?.redeem_code}</p>
                    <p>Descuento: {selectedCoupon?.discount_amount}</p>
                </div>
            </DeleteModal>
            <ToolboxHeader title="Cupones de descuento"/>
            <button onClick={() => {
                setShowCreateModal(true)
            }
            } className="btn btn-light-primary btn-pill ml-3">
                + Crear cupón
            </button>
            <div>
            </div>
            <Table>
                <TableHeader headers={["Titulo", "Código", "Descuento", "Activación", "Vencimiento", "Acción"]}/>
                <tbody>
                {coupons.length === 0 ? <EmptyTr/> : coupons.map(c => <tr className="text-center" key={c.id}>
                        <td>{c.title}</td>
                        <td>{c.redeem_code}</td>
                        <td>{c.discount_amount}</td>
                        <td>{formatDateTime(c.activate_on)}</td>
                        <td>{formatDateTime(c.expire_on)}</td>
                        <td>
                            <TableActionsCell
                                onClickDelete={() => {
                                    setSelectedCoupon(c)
                                    setShowDeleteModal(true)
                                }}
                                onClickEdit={() => {
                                    setSelectedCoupon(c)
                                    setShowCreateModal(true)
                                }}
                            />
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>
        </div>
    );
};

export default Coupons;
