import React from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm} from "formik";
import baseApi from "../../../../apis/baseApi";
import Select from "react-select";
import FieldError from "../../../../components/form/FieldError";
import {Sale} from "../../../Reports/Sales";
import yup from "../../../../utils/yup";
import {paymentMethods} from "../../../../data/paymentMethods";


type Props = {
    show: boolean,
    onHide: Function,
    sale: Sale,
    onSuccess: (paymentMethod: string) => void
}

const EditSalePaymentMethodModal = ({show, onHide, sale, onSuccess}: Props) => {
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState("")

    const renderAlert = () => {
        if (success === null) return;

        return <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };

    const ValidationSchema = yup.object().shape({
        payment_method: yup.string().required()
    })

    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik
                    validationSchema={ValidationSchema}
                    initialValues={{
                        payment_method: sale.payment_method,
                }} onSubmit={(values, {setSubmitting}) => {
                    setSuccess(null)
                    baseApi.patch(`/sales/${sale.id}/`, values).then(resp => {
                        setAlertMessage("Venta actualizada")
                        setSuccess(true)
                        onSuccess(values.payment_method!)
                    }).catch(err => {
                        setAlertMessage("Ocurrió un error al intentar actualizar la venta.")
                        setSuccess(false)
                    })
                }}>
                    {({isSubmitting, setFieldValue}) => (
                        <FormikForm>
                            <Modal.Header>
                                Actualizar método de pago
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <div>
                                    Cliente: {sale.member.external_id} - {sale.member.full_name}
                                </div>
                                <div>
                                    Plan: {sale.plan_items.map(e => e.plan).join(", ")}
                                </div>
                                <div>
                                    Productos: {sale.products.map(e => e.product).join(", ")}
                                </div>
                                <hr/>
                                <div className="font-weight-bolder font-size-h4">
                                    Total: ${sale.total}
                                </div>
                                <hr/>
                                <Select placeholder="Método de pago"
                                        options={paymentMethods.filter(e => e.value !== null)}
                                        onChange={(option) => setFieldValue("payment_method", option?.value)}/>
                                <FieldError name="payment_method"/>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button variant="success" type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : "Actualizar método de pago"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default EditSalePaymentMethodModal;
