import React, {useEffect, useState} from 'react';
import Separator from "../../../components/misc/Separator";
import {
    faAddressCard,
    faBirthdayCake,
    faCalendarDay, faCheck, faNotesMedical, faPhone,
    faPhoneAlt, faQuestionCircle,
    faShoePrints,
    faStore,
} from "@fortawesome/free-solid-svg-icons";
import {faFacebook, faInstagram} from "@fortawesome/free-brands-svg-icons";
import IconText from "../../../components/misc/IconText";
import {Member} from "../index";
import {formatDate} from "../../../utils/dateFormatters";
import ImageField from "../../../components/form/ImageField";
import baseApi from "../../../apis/baseApi";
import {Badge, Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import EditMemberModal from "./Modals/EditMemberModal";
import DeleteModal from "../../../components/modals/DeleteModal";
import EditMemberPasswordModal from "./Modals/EditMemberPasswordModal";
import loginApi from "../../../apis/loginApi";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {fetchStudioMemberExtraFields, StudioMemberExtraFieldsState} from "../../../actions/memberExtraFields";
import MemberExtraFieldsModal from "./Modals/MemberExtraFieldsModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getApiErrorMessage} from "../../../utils/apiErrors";

type MemberProfileProps = {
    member: Member
    setMember: (member: Member) => void
}

const MemberProfile = ({member, setMember}: MemberProfileProps) => {
    const dispatch = useDispatch()
    const profileExtraFieldsState = useSelector<StoreState, StudioMemberExtraFieldsState>(state => state.studioMemberProfileExtraFields)
    const [showEditModal, setShowEditModal] = useState(false);
    const [showEditPasswordModal, setShowEditPasswordModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showExtraFieldsModal, setShowExtraFieldsModal] = useState(false);
    const [recoverPasswordEmailStatus, setRecoverPasswordEmailStatus] = useState<string>();
    const [verifyingUser, setVerifyingUser] = useState(false);
    const [profileMessage, setProfileMessage] = useState<string>("");

    useEffect(() => {
        if (profileExtraFieldsState.fetching) {
            dispatch(fetchStudioMemberExtraFields())
        }
    }, [profileExtraFieldsState, dispatch]);


    const sendRecoverPasswordEmail = () => {
        setRecoverPasswordEmailStatus("sending")
        loginApi.post("/auth/users/reset_password/", {"email": member.user.email}).then((resp) => {
            setRecoverPasswordEmailStatus("success")
        })
    }

    const verifyUser = () => {
        setVerifyingUser(true)
        baseApi.patch(`/members/update/${member.id}/`, {"is_active": true}).then(resp => {
            setProfileMessage("Usuario verificado")
            setMember({...member, user: {...member.user, is_active: true}})
        }).catch(err => {
            setProfileMessage(getApiErrorMessage(err))
        })
    }

    return (
        <div>
            <EditMemberModal member={member} setMember={setMember} show={showEditModal} onHide={() => {
                setShowEditModal(false)
            }}/>
            <EditMemberPasswordModal show={showEditPasswordModal} onHide={() => {
                setShowEditPasswordModal(false)
            }} member={member}/>
            <MemberExtraFieldsModal member={member} setMember={setMember} show={showExtraFieldsModal} onHide={() => {
                setShowExtraFieldsModal(false)
            }}/>
            <DeleteModal url={`/members/update/${member.id}/`} onSuccess={() => {
            }} show={showDeleteModal} onHide={() => {
                setShowDeleteModal(false)
            }}>
                <div>
                    ¿Seguro que deseas eliminar este usuario?
                    <p className="font-weight-bolder p-0 m-0">{member.full_name} {member.last_name ?? ''}</p>
                    <p>{member.user.email}</p>
                </div>
            </DeleteModal>
            <div className="symbol symbol-50 symbol-lg-60 symbol-xxl-70 symbol-circle symbol-inline mx-auto mb-2">
                <ImageField src={member.img} onChange={file => {
                    const formData = new FormData();
                    formData.append("img", file, file.name);
                    baseApi.patch(`/members/update/${member.id}/`, formData).then()
                }}/>
            </div>
            <div className="font-weight-bold font-size-lg text-center">{member.full_name} {member.last_name ?? ''}</div>
            <div className="font-size-sm text-muted text-center">{member.user.email}</div>
            <div className="font-weight-bold text-muted text-center mt-2">
                <p>{member.current_credits} CRÉDITOS</p>
            </div>
            <div hidden={member.user.is_active}>
                <div hidden={!verifyingUser} className="text-success">Verificando usuario...</div>
                <Button onClick={verifyUser} hidden={verifyingUser} className="mr-2" size={"sm"} variant={"outline-success"}><FontAwesomeIcon icon={faCheck} /> Verificar</Button>
                <OverlayTrigger
                    placement="bottom"
                    overlay={
                    <Tooltip id={"verify-member"}>
                        El cliente no ha verificado su email, puedes verificar el usuario para que tenga acceso a la app.
                    </Tooltip>}>
                    <FontAwesomeIcon className="text-muted" icon={faQuestionCircle} />
                </OverlayTrigger>
            </div>
            <div>
                {profileMessage}
            </div>
            <Separator/>
            <div className="text-right">
                <Button size="sm" variant="outline-primary" onClick={() => setShowEditModal(true)}>Editar</Button>
            </div>
            <div className="text-left ml-3">
                <div className="pb-3">
                    <span className="font-size-sm text-muted font-weight-boldest mr-1">ID</span>
                    <span className="font-size-sm text-muted">{member.external_id}</span>
                </div>

                <IconText text={member.phone} icon={faPhoneAlt}/>
                <IconText text={member.shoe_size ?? ""} icon={faShoePrints} rotation={270}/>
                <IconText text={`Sucursal preferida: ${member.preferred_branch.name}`} icon={faStore}/>
                <IconText text={formatDate(member.birthday)} icon={faBirthdayCake}/>
                {/*<IconText text={member.user.gender === "M" ? "Hombre" : member.user.gender === "F" ? "Mujer" : "-"}*/}
                {/*          icon={faUserCircle}/>*/}
                <IconText text={`Miembro desde: ${formatDate(member.created_on)}`} icon={faCalendarDay}/>
                <IconText text={`Numero de emergencia: ${member.emergency_contact_phone}`} icon={faPhone}/>
                <IconText text={`Lesiones: ${member.injuries}`} icon={faNotesMedical}/>
                <IconText text={`Identificación: ${member.id_number}`} icon={faAddressCard}/>
                <IconText text={`Facebook: ${member.facebook_url}`} icon={faFacebook}/>
                <IconText text={`Instagram: ${member.instagram_username}`} icon={faInstagram}/>
            </div>
            <div hidden={profileExtraFieldsState.extra_fields.length === 0} className="text-left">
                <Button onClick={() => {
                    setShowExtraFieldsModal(true)
                }} size="sm" variant="link"> Campos extra</Button>
            </div>
            <Separator height={2}/>
            <Button onClick={() => {
                setShowEditPasswordModal(true)
            }} size="sm" variant="link">Cambiar contraseña</Button>
            <div className="mb-2" hidden={recoverPasswordEmailStatus !== "success"}>
                <Badge variant="success">Correo de recuperación de contraseña enviado</Badge>
            </div>
            <Button onClick={sendRecoverPasswordEmail} size="sm" variant="link"
                    hidden={recoverPasswordEmailStatus === "success"}
                    disabled={recoverPasswordEmailStatus === "sending"}>
                Enviar correo de recuperación de contraseña</Button>
            <Button onClick={() => {
                setShowDeleteModal(true)
            }} size="sm" variant="outline-danger" block>Eliminar usuario</Button>
        </div>
    );
};

export default MemberProfile;
