import {Dispatch} from "redux";
import {RegistrationFeesActionTypes} from "./types";
import baseApi from "../apis/baseApi";

export interface RegistrationFeesState {
    fetched: boolean;
    loading: boolean;
    fees: RegistrationFee[]
}

export interface RegistrationFeeAction {
    type: RegistrationFeesActionTypes;
    payload: any;
}

export const fetchRegistrationFees = (studioId: string) => {
    return async (dispatch: Dispatch) => {
        dispatch({type: RegistrationFeesActionTypes.FETCHING, payload: null})
        baseApi.get(`/registration-fees/?studio=${studioId}`).then((response) => {
            dispatch<RegistrationFeeAction>({
                type: RegistrationFeesActionTypes.FETCHED,
                payload: response.data.results
            })
        })
    }
}

export const addRegistrationFee = (fee: RegistrationFee) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: RegistrationFeesActionTypes.ADD_REGISTRATION_FEE,
            payload: fee
        })
    }
}

export const updateRegistrationFee = (fee: RegistrationFee) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: RegistrationFeesActionTypes.UPDATE_REGISTRATION_FEE,
            payload: fee
        })
    }
}

export const removeRegistrationFee = (feeId: string) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: RegistrationFeesActionTypes.REMOVE_REGISTRATION_FEE,
            payload: feeId
        })
    }
}

export interface RegistrationFee {
    id: string;
    public_visible: boolean;
    public_purchasable: boolean;
    name: string;
    price: string;
    does_not_expire: boolean;
    days_duration: number;
    purchase_attempt_message: string;
    created_on: string;
}