import React, {useState} from 'react';
import {Sale, SaleItem, SaleItemType, SaleLocations, SaleProductItem} from "../../Reports/Sales";
import {Button, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
import {faCashRegister, faEdit, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {formatDateTime} from "../../../utils/dateFormatters";
import CancelProductSaleItemModal from "./Modals/CancelProductSaleItemModal";
import {isBefore, parseISO, subDays} from "date-fns";
import {formatCurrency} from "../../../utils/currencyFormatters";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import EditSalePaymentMethodModal from "./Modals/EditSalePaymentMethodModal";

interface Props {
    sale: Sale
    onClose: () => void
    onUpdateProductSaleItem: (item: SaleProductItem) => void
    onUpdateSale: (sale: Sale) => void
}

const MemberSaleReceipt = ({sale, onClose, onUpdateSale, onUpdateProductSaleItem}: Props) => {
    const studioId = useSelector<StoreState, string>(state => state.studio.id)
    const [showCancelProductItemModal, setShowCancelProductItemModal] = useState(false);
    const [selectedProductItem, setSelectedProductItem] = useState<SaleProductItem>();
    const currency = useSelector<StoreState, string>(state => state.studio.preferences.currency)
    const [showEditModal, setShowEditModal] = useState(false);

    const getSaleItemTypeText = (saleItem: SaleItem): string => {
        switch (saleItem.item_type) {
            case SaleItemType.fee:
                return "Multa"
            case SaleItemType.product:
                return "Producto"
            case SaleItemType.plan:
                return "Plan"
            case SaleItemType.registrationFee:
                return "Inscripción"
        }
    }

    return (
        <div className="p-5 mr-10">
            <EditSalePaymentMethodModal show={showEditModal} onHide={() => setShowEditModal(false)} sale={sale}
                                        onSuccess={(pm) => {
                                            sale.payment_method = pm
                                            onUpdateSale(sale)
                                        }}/>
            <CancelProductSaleItemModal
                item={selectedProductItem}
                onSuccess={() => {
                    onUpdateProductSaleItem({...selectedProductItem!, cancelled: true})
                }}
                show={showCancelProductItemModal}
                onHide={() => setShowCancelProductItemModal(false)}/>
            <div className="text-right">
                <button onClick={onClose} className="btn btn-outline-danger btn-sm btn-circle px-3 py-1">
                    <FontAwesomeIcon icon={faTimes}/> Cerrar
                </button>
            </div>
            <span hidden={sale.status !== 2}
                  className="label label-inline label-light-danger font-weight-bolder rounded-xl">
                                        <FontAwesomeIcon className='mr-1' icon={faTimes}/>
                                        CANCELADO
                                    </span>
            <h3 className="text-muted">#{sale.id.slice(-6).toUpperCase()}</h3>
            {sale.payment_method === null
                ? <div className="font-weight-bold font-size-lg">Pendiente de pago (deuda)</div>
                : <div
                    className="font-weight-bold font-size-lg">{sale.payment_method} {sale.location === SaleLocations.POS ?
                    <FontAwesomeIcon className="text-muted" icon={faCashRegister}/> : ""}
                    <div hidden={sale.paid_on?.substring(0, 10) === sale.created_on.substring(0, 10)}
                         className="font-weight-normal"><span className="text-success">*</span>Deuda
                        pagada el: {sale.paid_on ? formatDateTime(sale.paid_on) : ""}</div>
                    <Button hidden={sale.status !== 1 || sale.location !== SaleLocations.POS  || isBefore(parseISO(sale.paid_on!), subDays(new Date(), 15))} onClick={() => setShowEditModal(true)}  variant={"link"} size={"sm"}>
                        <FontAwesomeIcon icon={faEdit}/>
                    </Button>
                </div>
            }
            <div>Atendió: {sale.transaction_by}</div>
            <div>{formatDateTime(sale.paid_on ?? sale.created_on)}</div>
            <Table hover>
                <tr>
                    <th className="text-right"></th>
                    <th>Cantidad</th>
                    <th>Artículo</th>
                    <th>Tipo</th>
                    <th className="text-right">Precio</th>
                </tr>
                <tbody>
                {sale.items.map((item) => <tr key={item.id} className="font-size-sm align-middle text-left">
                        <td></td>
                        <td>{item.quantity}</td>
                        <td>{item.name}</td>
                        <td>{getSaleItemTypeText(item)}</td>
                        <td className="text-right">{item.price}</td>
                    </tr>
                )}
                {sale.plan_items.map((item) => <tr key={item.id} className="font-size-sm align-middle text-left">
                        <td></td>
                        <td>{item.quantity}</td>
                        <td>{item.plan}
                            <span hidden={!item.cancelled}
                                  className="label label-inline label-light-danger font-weight-bolder rounded-xl">
                                        <FontAwesomeIcon className='mr-1' icon={faTimes}/>
                                        CANCELADO
                            </span>
                        </td>
                        <td>Plan</td>
                        <td className="text-right">{item.price}</td>
                    </tr>
                )}
                {sale.products.map((item) => <tr key={item.id}
                                                 className={`font-size-sm align-middle text-left ${item.cancelled ? 'bg-secondary' : ''} `}>
                        <td className="text-right w-30px">
                            <button
                                hidden={sale.status === 2 || item.cancelled || isBefore(parseISO(sale.created_on), subDays(new Date(), 15))}
                                onClick={() => {
                                    setSelectedProductItem(item)
                                    setShowCancelProductItemModal(true)
                                }} className="btn btn-icon btn-light btn-xs btn-hover-danger mr-1">
                                <FontAwesomeIcon icon={faTrashAlt}/>
                            </button>
                        </td>
                        <td>{item.quantity}</td>
                        <td>{item.product} <span hidden={!item.cancelled}
                                                 className="label label-inline label-light-danger font-weight-bolder rounded-xl">
                                        <FontAwesomeIcon className='mr-1' icon={faTimes}/>
                                        CANCELADO
                                    </span>
                        </td>
                        <td>Producto</td>
                        <td className="text-right">{item.price}</td>

                    </tr>
                )}
                </tbody>
            </Table>
            <div className="text-right h6">
                Descuento: -${parseFloat(sale.discount ?? "0")}
            </div>
            <div className="text-right h3">
                Total: {formatCurrency(parseFloat(sale.total), currency)}
            </div>
        </div>
    );
};

export default MemberSaleReceipt;
